import axios from "axios";

export const list = (name, page, pageSize) => {
  return axios.get("/sys/user/list", {
    params: {
      name,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/sys/user/save", vo);
};

export const detail = id => {
  return axios.get(`/sys/user/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/sys/user/${id}/edit`, vo);
};

export const deleteUser = id => {
  return axios.delete(`/sys/user/${id}/delete`);
};
