import { reactive, ref } from "vue";
import { list } from "@/api/sys/sysUserApi";
import useMessage from "@/hooks/useMessage";
const useSysUser = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const userVo = reactive({
    account: "",
    name: "",
    password: "",
    roleId: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "账号",
      align: "center",
      dataIndex: "account",
    },
    {
      title: "姓名",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "角色",
      align: "center",
      dataIndex: "roleName",
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params.name, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    name: "",
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    userVo,
  };
};
export default useSysUser;
