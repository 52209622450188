<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="添加用户"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="账号" v-bind="validateInfos.account">
        <a-input
          v-model:value="userVo.account"
          :maxlength="16"
          placeholder="用户登录账号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="姓名" v-bind="validateInfos.name">
        <a-input
          v-model:value="userVo.name"
          :maxlength="16"
          placeholder="用户姓名"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="密码" v-bind="validateInfos.password">
        <a-input-password
          v-model:value="userVo.password"
          :maxlength="16"
          placeholder="登录密码"
        ></a-input-password>
      </a-form-item>
      <a-form-item label="所属角色" v-bind="validateInfos.roleId">
        <a-select
          v-model:value="userVo.roleId"
          placeholder="用户角色"
          allow-clear
        >
          <a-select-option
            v-for="item in roles"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, reactive, toRefs } from "vue";
import useSysUser from "@/hooks/system/useSysUser";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { allRoles } from "@/api/sys/sysRoleApi";
import { save } from "@/api/sys/sysUserApi";
import md5 from "js-md5";
import config from "@/config";

const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const { userVo } = useSysUser();
    const roles = reactive([]);
    const { sysUserRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(userVo, sysUserRules);
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的用户?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              account: userVo.account,
              name: userVo.name,
              roleId: userVo.roleId,
              password: md5(config.pwdPrefix + userVo.password),
            };
            await save(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      try {
        let { data } = await allRoles();
        roles.push(...data);
      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(pros),
      disabled,
      visible,
      afterClose,
      roles,
      userVo,
      validateInfos,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
